<template>
  <v-container class="py-0 bg-glass-effect-light" style="height: 100% !important;" fluid>
    <BackArrow style="z-index: 1000" :route="'miniHomeCovid'" v-if="isLoadedQuestions" />
    <v-row class="pt-14" >
      <v-col v-if="isLoadedQuestions" cols="12" style="height: 100%">
        <v-stepper class="stepper-layout pa-0 ma-0" v-model="stepperCount">
          <v-stepper-content class="pa-0 ma-0" v-for="(question, index) in questionsOptions.questionList"
                             :key="index" :step="index + 1" style="height: 100%">

            <!--show progress-->
            <v-row class="mx-0" v-if="questionsOptions.showProgress">
              <v-col cols="12" align="center">
                <v-progress-circular :rotate="-90" :size="250" :width="7" color="#2EC9D3"
                    :value="((index + 1) * 100) / questionsOptions.questionList.length"
                >
                  <span class="progress-description">{{index + 1}}</span><span class="progress-total-description"> /{{questionsOptions.questionList.length }}</span>
                </v-progress-circular>
              </v-col>
            </v-row>

            <!--multipla escolha-->
            <v-row class="mx-1 mb-2"  v-if="question.questionType.description === 'Múltipla escolha'">

              <!--answer custom title and description-->
              <v-col class="mt-5 mb-6" cols="12">
                <div><span v-html="question.questionText"></span></div>
              </v-col>

              <!--answer list-->
              <v-col cols="12">
                <v-card v-for="answer in question.answerOptionList" :key="answer.id"
                        rounded="lg" elevation="8" class="px-5 mb-5" @click="selectMultiple(question, answer, questionsOptions)"
                >
                  <!--asnwer with icon-->
                  <v-row class="mb-7" align="center" v-if="answer.answerIcon">
                    <v-col cols="3" xs="2" sm="1">
                      <v-img v-if="hasSvg(answer.answerIcon)" :src="require('../../../public/img/icons/survey/' + answer.answerIcon +'')" min-width="36" min-height="36" max-width="46" max-height="46"></v-img>
                      <v-icon v-else>{{answer.answerIcon}}</v-icon>
                    </v-col>
                    <v-col class="py-0 pl-0 ml-0 default-asnwer-description" cols="9" xs="10" sm="11">
                      <span class="py-2" v-html="answer.answerText"></span>
                    </v-col>
                  </v-row>
                  <!--asnwer without icon-->
                  <v-row align="center" v-else>
                    <v-col class="default-asnwer-description py-5" cols="12">
                      <span class="py-2" v-html="answer.answerText"></span>
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>

              <!--skip question-->
              <v-col cols="12" align="center" v-if="!question.answerRequired">
                <v-btn class="skip-question-title" text x-large block @click="nextQuestion(question)">Pular pergunta</v-btn>
              </v-col>
            </v-row>

            <!--caixa de selecao-->
            <v-row class="mx-0" v-else-if="question.questionType.description === 'Caixas de seleção'">

              <!--answer custom title and description-->
              <v-col class="mb-5 mt-6" cols="12">
                <div><span v-html="question.questionText"></span></div>
              </v-col>

              <!--answer list-->
              <v-col cols="12">
                <v-card v-for="answer in question.answerOptionList" :key="answer.id"
                        rounded="lg"
                        elevation="8"
                        class="px-5 mb-7"
                >
                  <!--asnwer with icon-->
                  <v-row>
                    <v-col class="py-3">
                      <v-checkbox class="my-3" color="red" :value="answer.id" hide-details v-model="answersCheckbox" @click="clickCheck(question.id)">
                        <template v-slot:label>
                          <div>
                            <span class="checkbox-description">{{answer.answerText}}</span>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>

              <!--next question-->
              <v-col cols="12" align="center">
                <GenericBtn
                    class="mb-6"
                    :onClick="() => (nextQuestion(question))"
                    :btn-props="{block: true,large: true, color: 'primary'}"
                >Avançar</GenericBtn>
              </v-col>
            </v-row>

          </v-stepper-content>
        </v-stepper>
      </v-col>

      <!--loading-->
      <v-col v-else style="height: 100vh" cols="12">
        <v-row justify="center" style="height: 100%">
          <v-col cols="6" align="center" align-self="center">
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-dialog v-model="dialog.show" persistent max-width="400px">
          <v-card :style="dialog.style" class="overflow-x-auto" rounded="lg">
            <v-row class="pa-6" style="height: 100%; width: 103%">
              <v-col cols="12" align-self="start">
                <v-row>
                  <v-col cols="12">
                    <v-btn icon :color="dialog.colorDefault" @click="closeDialogAndNext()">
                      <v-icon large>fas fa-times</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col col="12">
                    <span v-html="dialog.message"></span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" align-self="end">
                <GenericBtn
                  :btn-props="{
                    block: true,
                    xLarge: true,
                    rounded: true}"
                  :on-click="() => (closeDialogAndNext())"
                >Próximo</GenericBtn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getById, registerUserAnswer} from '../../common/survey-service';
import BackArrow from "@/components/covid/back-arrow";
import {mapMutations} from "vuex";
import GenericBtn from "../../components/generic-btn/index"

export default {
  name: "index",
  data: () => ({
    icon: 'fas fa',
    answersMultiples: [],
    answersCheckbox: [],
    dialog: {
      message: '',
      show: false,
      colorDefault: '#532E88',
      style: {
        color: '#FFFFFF',
        backgroundColor: '#2EC9D3',
        height: '80vh'
      }
    },
    questionsAnswered: [],
    questionOrder: [],
    questionsShowed: [],
    isLoadedQuestions: false,
    questionsOptions: null,
    stepperCount: 1,
    answerRegisterDto: {
      answerDate: null,
      answerOptionIdList: [],
      surveyId: 0,
      totalQuestionViewed: 0
    },
    surveyId: 0,
    finalRoute: undefined
  }),
  components:{
    BackArrow,
    GenericBtn
  },
  created () {
    this.loading(true)
    this.loadRouteQueryParams()
    this.loadSurvey()
  },
  methods: {
    ...mapMutations(['loading']),
    loadRouteQueryParams(){
      this.surveyId = this.$route.query.id
      this.finalRoute = this.$route.query.finalRoute
    },
    loadSurvey() {
      getById(this.surveyId)
          .then((response) => {
            this.questionsOptions = response.data
            if(this.questionsOptions){
              this.isLoadedQuestions = true
              this.questionsOptions.questionList.sort((a,b) => (
                  a.displayOrder > b.displayOrder ? 1 : -1
              ))
              this.questionsOptions.questionList.forEach(question => {

                if(question.answerOptionList) {
                  question.answerOptionList.sort((a, b) => (
                      a.displayOrder > b.displayOrder ? 1 : -1
                  ))
                }
              })
              this.questionsOptions.questionList.forEach(question => {
                this.questionOrder.push(question.id)
              })
            }
          })
          .catch((error) => {
            this.isLoadedQuestions = false
          }).finally(()=>{
            this.loading(false)
          })
    },
    hasSvg (icon) {
      if(icon != null && icon.trim() != ''){
        return icon.split('.').pop().includes('svg')
      }
      return false
    },
    selectMultiple (question, answer, questionsOptions) {
      this.addAnswer(question, answer)
      this.addQuestionShowed(question)
      this.verifyShowDialog(answer, question, questionsOptions)
    },
    addAnswer (question, answer) {
      const questionIndex = this.questionsAnswered.indexOf(question.id)
      if(questionIndex < 0) {
        this.questionsAnswered.push(question.id)
        this.answersMultiples.push(answer.id)
      } else {
        this.answersMultiples.splice(questionIndex, 1, answer.id)
      }
    },
    addQuestionShowed(question) {
      this.questionsShowed.push(question.id)
    },
    verifyShowDialog (answer, question, questionsOptions) {
      const COLOR_POSITIVE = '#2EC9D3'
      const COLOR_NEGATIVE = '#FE7B93'

      if (answer.rightOption && answer.rightAnswerDescription && questionsOptions.showRightAnswers) {
        this.showDialogInformation(COLOR_POSITIVE, answer.rightAnswerDescription, true)
      } else if (!answer.rightOption && answer.wrongAnswerDescription && questionsOptions.showWrongAnswers) {
        this.showDialogInformation(COLOR_NEGATIVE, answer.wrongAnswerDescription, true)
      } else {

        if(question.questionGoTo.length > 0 && question.questionGoTo[0].nextQuestionId != null
            && question.questionGoTo[0].answerOptionId != null && question.questionGoTo[0].answerOptionId == answer.id) {

          const nextIndex = this.questionOrder.indexOf(question.questionGoTo[0].nextQuestionId)
          this.stepperCount = nextIndex + 1
        }else {
          this.forwardOrFinish()
        }
      }
    },
    showDialogInformation(color, message, showStatus){
      this.dialog.style.backgroundColor = color
      this.dialog.message = message
      this.dialog.show = showStatus
    },
    closeDialogAndNext() {
      this.dialog.show = false
      this.forwardOrFinish()
    },
    nextQuestion(question) {
      window.scrollTo(0,0);
      this.addQuestionShowed(question)
      this.forwardOrFinish()
    },
    forwardOrFinish(){
      if(this.stepperCount < this.questionsOptions.questionList.length){
        this.stepperCount++
      }else {
        this.isLoadedQuestions = false
        this.createAnswerRegisterDto()
        this.registerAnswersService()
        this.loading(true)
      }
    },
    clickCheck(questionId){
      let questionIndex = this.questionsAnswered.indexOf(questionId)
      if(questionIndex < 0) {
        this.questionsAnswered.push(questionId)
      }
    },
    createAnswerRegisterDto() {
      this.answerRegisterDto.answerDate = Date.now();
      this.answerRegisterDto.answerOptionIdList = this.answersMultiples.concat(this.answersCheckbox)
      this.answerRegisterDto.surveyId = this.surveyId
      this.answerRegisterDto.totalQuestionViewed = this.questionsShowed.length
    },
    registerAnswersService() {
      if(this.finalRoute){
        this.$router.push({ name: this.finalRoute, params: { userAnswerSurvey : this.answerRegisterDto } })
      } else {
        this.saveSurvey();
      }
    },
    saveSurvey(){
      registerUserAnswer(this.answerRegisterDto)
              .then((response) => {
                this.redirectResultRoute(response.data)
              })
              .catch((error) => {
                this.$router.push({name: '/quizResult'})
              })
    },
    redirectResultRoute(userAnswerSurvey){
      if (userAnswerSurvey.survey.testMode && userAnswerSurvey.resultInterval.route != null) {
        //default route in userAnswerResponse
        this.$router.push({ name: userAnswerSurvey.resultInterval.route, params: { userAnswerSurvey } })
      } else {
        //back to left route
        this.$router.go(-1)
      }
    },
  }
}
</script>

<style scoped>

  /*background effect blur*/

  .stepper-layout {
    box-shadow: none;
    background-color: transparent !important;
  }

  .progress-description {
    font-size: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    color: #532E88;
  }

  .progress-total-description {
    font-size: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    color: #999;
  }

  .skip-question-title, .previous-button {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #868698;
    text-transform: capitalize;
    border-radius: 20px;
  }

  .btn-next-checklist {
    color: white;
    text-transform: capitalize;
    border-radius: 20px;
  }

  .default-asnwer-description {
    font-family: Open Sans;
    font-weight: 500;
    font-size: 18px;
    color: #532E88;
    font-weight: bold;
  }

  .checkbox-description {
    font-family: Open Sans;
    font-size: 18px;
    color: #532E88;
  }

  .stepper-layout {
    box-shadow: none !important;
    background-color: transparent !important;
  }
</style>
