import axios from "axios";
import $auth from "@/common/auth";

const SURVEYS_URI = '/survey/surveys';
const USER_ANSWER = '/survey/surveys/user-answer'

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const getById = (id) => {
    const url = `${VUE_APP_API_URL}${SURVEYS_URI}/${id}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        }
    });
};

const registerUserAnswer = (answerRegisterDto) => {
    const url = `${VUE_APP_API_URL}${USER_ANSWER}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + $auth.getAccessToken(),
            'Content-Type': 'application/json'
        },
        data: answerRegisterDto
    });
};

export {
    getById,
    registerUserAnswer
};
